import '../../styles/pages/spring-2020/lessons-sweet-potato.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet";
import ScrollAnimation from 'react-animate-on-scroll';
import { Parallax, Background } from 'react-parallax';
import DownArrow from '../../components/downarrow/downarrow.js'
import MoreStories from "../../components/spring-2020-stories/more-stories"
import StoryNav from "../../components/spring-2020-stories/story-nav"
import Related from "../../components/spring-2020-stories/related-stories"


import Title from '../../images/spring-2020/lessons-sweet-potato/title.png';
import sweetPotato from '../../images/spring-2020/lessons-sweet-potato/sweet-potato.jpg';

import escobarPotato from '../../images/spring-2020/lessons-sweet-potato/c-escobar-potato.png';
import escobarPotato2 from '../../images/spring-2020/lessons-sweet-potato/c-escobar-potato2.png';
import nhan from '../../images/spring-2020/lessons-sweet-potato/c-melanie-nhan.png';
import johnson from '../../images/spring-2020/lessons-sweet-potato/sara-johnson2.jpg';
import potatoPie from '../../images/spring-2020/lessons-sweet-potato/c-sweet-potato-pie.png';
import vermicomposting from '../../images/spring-2020/lessons-sweet-potato/vermicomposting.jpg';
import monkeyBusiness from '../../images/spring-2020/lessons-sweet-potato/monkey-business.jpg';
import purpleNutsedge from '../../images/spring-2020/lessons-sweet-potato/purple-nutsedge.jpg';
import purpleNutsedge2 from '../../images/spring-2020/lessons-sweet-potato/purple-nutsedge2.jpg';
import pie from '../../images/spring-2020/lessons-sweet-potato/pie.png';
import sweetpotatoabove from '../../images/spring-2020/lessons-sweet-potato/sweet-potato-above.jpg';

import socialBanner from "../../images/spring-2020/social-banners/lessons-sweet-potato.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    this.updateDimensions();
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  render() {
    var pageTitle = 'Lessons From the Lowly Sweet Potato';
    var pageDesc = 'Teresa Zamora surveys the pantry at Monkey Business Cafe and spies the new arrivals — fresh sweet potatoes, still caked with field mud. Flipping through the vast library of recipes in her mind, she settles on sweet potato pie and sweet potato muffins.';


    return (
      <Layout headerStyle="black" locationInfo={this.props.location} issue="spring-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="lessons-sweet-potato" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        

          <div className="feature-hero">

            <div className="hero-text">
              <h1><img src={Title} alt={pageTitle} className="title-graphic animated fadeIn delay-1s" /></h1>
              <img className="potato-image animated fadeIn" src={sweetPotato} alt="Sweet potatoes" />
            </div>
            
            <p className="sub-heading animated fadeInUp delay-2s">U-ACRE Researchers Unearth Knowledge and Cultivate Sustainability</p>
            
            <p className="author animated fadeInUp delay-2s">Story by Karen Lindell, Photos by Matt Gush</p>

            <DownArrow />
          </div>
          
          <article className="wrap small" id="intro">

            <ScrollAnimation animateIn="fadeIn">
              <figure className="align-left align-outside large circle">
                <img src={potatoPie} alt="Teresa Zamora serves sweet potato pie and sweet potato muffins" />
                <figcaption>Teresa Zamora</figcaption>
              </figure>
              
              <p><span className="intro-text">Teresa Zamora surveys the pantry</span> at Monkey Business Cafe and spies the new arrivals — fresh sweet potatoes, still caked with field mud. Flipping through the vast library of recipes in her mind, she settles on sweet potato pie and sweet potato muffins.</p>
              <p>As executive chef for the Fullerton-based cafe, Zamora is, of course, passionate about food. But she’s even more excited about the restaurant sourcing its food from the Fullerton Arboretum Learning Farm at Cal State Fullerton. “When you eat fresh, locally sourced food, nothing compares to it,” she declares. “And I prefer to make food that people remember.”</p>
              <p>The farm-to-table experience is made possible through a partnership with CSUF’s Urban Agriculture Community-based Research Experience (U-ACRE) project.</p>
              </ScrollAnimation>
            </article>
  
            <section className="spuds">
              <ScrollAnimation animateIn="fadeIn">
              <article className="wrap small">
              <h2>Growing Spuds and Scientists</h2>

              <figure className="align-right align-outside large circle black-caption-text caption-top">
                <img src={escobarPotato} alt="Mitsue Escobar pulls a sweet potato out of the ground" />
                <figcaption>Amy Heil and Mitsue Escobar</figcaption>
              </figure>
              
              <p>At the Fullerton Arboretum Learning Farm, Mitsue Escobar ’18 (B.A. anthropology) pulls a sweet potato out of the ground. There aren’t many left; it’s the end of the harvest that provided an impressive 705 pounds of tubers — a higher yield per square foot than achieved by commercial growers in California.</p>

              <p>As a U-ACRE member and graduate student in environmental studies, Escobar oversees other U-ACRE fellows, hundreds of service-learning students and a handful of Monkey Business Cafe employees who help plant, cultivate, harvest, measure and record data on the sweet potato crop. “I love that everything I’ve learned in class, it connects here,” she says.</p>
              
            </article>
            </ScrollAnimation>
          </section>
            
              <article className="wrap small">

              <ScrollAnimation animateIn="fadeIn">
              <figure className="align-wide" style={{top: '-50px', position: 'relative'}}>
                <img src={johnson} alt="Sara Johnson and Alice Sumrall standing in arboretum" />
                <figcaption>Sara Johnson and Alice Sumrall</figcaption>
              </figure>
              </ScrollAnimation>

              <ScrollAnimation animateIn="fadeIn">

            <Related slug="lessons-sweet-potato" />


              <p>The sweet potato plot is marked off in a grid pattern, and students carefully weigh the yield from each section at harvest and track such variables as the surface area of the sweet potato vine throughout the semester. “Everything we plant, the planting configuration and the sequence of plantings is consistent with research objectives,” explains Sara Johnson, U-ACRE program director and professor of anthropology.</p>

              <p>A student observation at the farm or  a discussion with arboretum staff about the crop could turn into a research project for the U-ACRE fellows. Johnson, one of the recipients of the <a href="http://news.fullerton.edu/2019su/CSU-Award-Sara-Johnson.aspx" target="_blank" className="external-link">2019 California State University Faculty Innovation and Leadership Award</a>, encourages students to look at research as problem-solving for community partners, and she works with them to design and execute studies that address such problems.</p>
              </ScrollAnimation>
              </article>

            
              <ScrollAnimation animateIn="fadeInUp"><img src={purpleNutsedge2} alt="Purple nutsedge" /></ScrollAnimation>
  
            <div className="nightmare">
              <article className="small wrap">
                <ScrollAnimation animateIn="fadeIn">
                <h2>Nutsedge Nightmare</h2>
  
                <p>One of the biggest issues the arboretum has faced is actually the reason U-ACRE members decided to grow sweet potatoes in the first place: purple nutsedge. The weed resembles grass, but it’s an aggressive invasive plant that is difficult to control and reduces crop yields.</p>
                </ScrollAnimation>
              </article>
            </div>


              <ScrollAnimation animateIn="fadeIn">
              <div className="sweet-potato-1">
              <article className="small wrap"><figure className="align-outside align-right large">
                  <img src={purpleNutsedge} alt="Purple nutsedge blooms" />
                  <figcaption>Purple nutsedge</figcaption>
                </figure>
                <p>Because U-ACRE treats the farm as an ecosystem, they want to address the nutsedge invasion without using herbicides, Johnson shares. “We looked at past, as well as current traditional farming practices, to develop a solution. The Raramuri people from northern Mexico plant sweet potatoes to kill nutsedge and U.S. Department of Agriculture lab studies have found different cultivars of sweet potato produce different allelotoxins. Our research design and outcome measures are a product of combining these different, but complementary types of information.”</p>
  
                <p>“We’ve seen good results,” says Escobar. “Interestingly, we found that it wasn’t the amount of sweet potato growing in a grid square but how big the vine was that affected the nutsedge.” This is a win-win, she continues: “We could extract the allelotoxins from the vine, but it’s actually better to plant the sweet potato because you not only kill the nutsedge but get food for the community too.”</p>
                <p>After the sweet potatoes are extracted, Escobar lays the vine along the edge of the plot to form a barrier to keep nutsedge from making its way into the farm soil.</p>
              </article>
              </div>
              </ScrollAnimation>

            <article className="small wrap">
              <ScrollAnimation animateIn="fadeIn">
              <h2>Research Sprouts More Research</h2>

              <figure className="align-left align-outside large circle">
                <img src={escobarPotato2} alt="Amy Heil categorizing sweet potato harvest" />
                <figcaption>Amy Heil</figcaption>
              </figure>

              <p>A final step in the sweet potato harvest involves counting and categorizing the amount of nutsedge left in each square of the farm plot. Seeing positive results from the sweet potatoes spurs additional research that Escobar is working on now — investigating which varietal of tuber is the most effective weed killer. U-ACRE originally planted Vardaman sweet potatoes, but the most recent harvest included Vardaman and Puerto Rico varieties.</p>

              <p>Escobar also noticed that in the recent crop, some sweet potato vines grew horizontally. She hypothesized that it may have been due to the cover crop grown in those areas. Cover crops prepare the soil for the harvest crop.</p>

              <figure className="align-right large align-outside">
                <img src={sweetpotatoabove} alt="Sweet potatoes in ground" />
              </figure>
              
              <p>“Sweet potatoes need loose, nitrogen-rich soil, so we planted daikon radish and crimson clover to create those conditions,” she explains. Since the cover crop was planted in a pattern, Escobar deduced that the horizontal vines occurred where the crimson clover was planted, suggesting that the soil there was not soft enough.</p>
              
              <p>Beyond the farm and Monkey Business Cafe, U-ACRE students work at the arboretum’s apiary — a place where bees are kept — and with community partners at Future Foods Farms, Pathways of Hope and four local schools.</p>
              
              <p>“Part of the mission of the Fullerton Arboretum is to serve faculty and students in research and education objectives like U-ACRE,” says Gregory Pongetti ’06 (B.S. biological science), the arboretum’s living collections curator. “I think the sweet potato project needs to continue in order to learn more about the long-term control of nutsedge.”</p>
              </ScrollAnimation>
  
            </article>

              <ScrollAnimation animateIn="fadeIn">
              <div className="sweet-potato-2">
                <article className="wrap small">
              <h2>Putting a Value on Produce</h2>

              <figure className="align-right align-outside large circle black-caption-background">
                <img src={nhan} alt="Nehemiah Rodriguez and Melanie Nhan reviewing papers" />
                <figcaption>Nehemiah Rodriguez and Melanie Nhan</figcaption>
              </figure>
              
              <p>As an economics major, U-ACRE member Melanie Nhan relishes the opportunity to analyze the nutrient value and pricing on menu items at Monkey Business Cafe.</p>

              <p>“We want to know the nutrients going into human bodies,” she explains. Using software in the Division of Anthropology’s computer lab, Nhan searches for each sweet potato pie ingredient and creates a nutrient label. “Generally when you compare Monkey Business Cafe’s nutrient value to others, it has a much better profile.”</p>
              </article>
              </div>
              </ScrollAnimation>
              

            

            <ScrollAnimation animateIn="fadeIn">
            <Parallax
              bgImage={monkeyBusiness}
              bgImageAlt="Lucien Nadal and Ivan Torreblanca in kitchen"
              strength={400}
              className="full-width"
            >
              <div className="caption">Lucien Nadal and Ivan Torreblanca</div>
              <div className="height" />
            </Parallax>
            </ScrollAnimation>

            <article className="wrap small">
              
            <ScrollAnimation animateIn="fadeIn">
              <p>The part Nhan enjoys most, though, is cost analysis. “I look at market prices where the ingredients are purchased, which is really important because margins are so low in the business. Then I see if we can alter ingredients so the menu item costs less but maintains the same nutritional value.”</p>

              <p>Nhan loves that U-ACRE allows her to pursue her passions in sustainability and business while giving back to the community.</p>
              
              <p>Similarly, Escobar dreams of becoming an urban planner and opening small farms “to show the community that you don’t need a lot of land or chemicals to grow food.”</p>
              </ScrollAnimation>
            </article>
            <ScrollAnimation animateIn="fadeIn">
            <div className="worms">
              <article className="wrap small">
                
                <h2>Worms Attacking Waste</h2>
                <p>U-ACRE is a conduit for sharing knowledge between community partners, too. The program has gained expertise in vermicomposting — using worms to break down waste and their excrement to fertilize. Now Johnson is helping Monkey Business Cafe begin a vermicomposting program.</p>
                
                <p>In the new composting station behind the restaurant, Chef Zamora dumps sweet potato peelings and other kitchen waste into the container filled with soil and more than 5,000 worms. Johnson covers it with newspaper.</p>
  
                
              </article>
            </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn">
            <Parallax
              bgImage={vermicomposting}
              bgImageAlt="Worms in vermicomposting station"
              strength={400}
              className="full-width"
            >
              {/* <div className="caption">
                
              </div> */}
              <div className="height" />
            </Parallax>
            </ScrollAnimation>

            <article className="wrap small">
              <ScrollAnimation animateIn="fadeIn">

              <p>The worms will decompose the waste, creating castings (worm excrement), which will then be used as soil fertilizer for the herbs being grown and used at the cafe. The goal is to figure out exactly the right amount of food waste and paper to input so the waste is completely processed in a 24-hour period — setting up a sustainable cycle.</p>

              <figure className="align-right align-outside large">
                <img src={pie} alt="Slice of sweet potato pie and muffins" />
              </figure>
              
              <p>Zamora closes the composting station lid. She is eager to get the process started, with the eventual goal of vermicomposting not only kitchen waste but all food waste at the restaurant.</p>

              <p>That will take time and experimentation. But right now, she has a more pressing matter to attend to: baking those scrumptious sweet potato pies and muffins. <span className="end-mark" /></p>

              <p className="learn-more">To learn more about U-ACRE, visit <a href="http://sustainability.fullerton.edu/U-ACRE/" target="_blank">sustainability.fullerton.edu/u-acre</a>.</p>
              </ScrollAnimation>
            </article>
            <div className="sprout-footer" />

            {/* <ScrollAnimation animateIn="fadeIn">
            
            <setion className="about-u-acre">
              <div className="about-image"></div>
              <div className="about-content">
                <h2>About U-ACRE</h2>
                <p>Founded in 2011 by Sara Johnson, professor of anthropology and project director, U-ACRE provides hands-on research and other educational opportunities for CSUF students while engaging and helping the local community. It has won the 2017 Best Practice Award from the California Higher Education Sustainability Conference and a 2014 New York Life Higher Education Civic Engagement Award.</p>
                
                <p>The collaboration between U-ACRE, Monkey Business Cafe and Fullerton Arboretum is a joint effort through I-CAN, or Integrating Culinary Arts, Agriculture and Nutrition. I-CAN prepares former foster youth for careers through a farm-to-table training program where they grow produce with U-ACRE students at the farm, receive training in culinary arts, recipe development and food preparation at the cafe and conduct nutrient analysis of restaurant recipes with U-ACRE faculty and students.</p>
                
                <p>Primarily funded by the USDA National Institute of Food and Agriculture Hispanic Serving Institutions Education Grants program, I-CAN and U-ACRE use urban agriculture as a lens to understand complex issues, including food and housing insecurity, nutritional sufficiency, environmental justice and sustainable development.</p>
                
                <h3>Community Partners and Learning Projects</h3>
                <ul>
                  <li>Fullerton Arboretum Learning Farm and Apiary - practicing agroecology</li>
                  <li>Monkey Business Cafe - farm to table, food waste diversion through vermicomposting, nutritional and pricing analysis</li>
                  <li>Future Foods Farm - aquaponic farm</li>
                  <li>K-12 Schools - school gardens, lunch waste diversion through vermicomposting</li>
                  <li>Project Learning Tree - environmental education</li>
                  <li>Pathways of Hope - garden and culinary</li>
                </ul>
              </div>
            </setion>
            </ScrollAnimation> */}

            <MoreStories slug="lessons-sweet-potato" />
            <StoryNav slug="lessons-sweet-potato" />


          



      </Layout>
    );
  }
} 